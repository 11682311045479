@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: inherhit;
}

html {	
	box-sizing: border-box;
	font-size: 62.5% /* 1rem = 10px, 10px / 16px (= browser default) = 62.5% */ 
}

/* Optional: This varies based on project design */
body {
	font-family: 'Poppins', 'sans-serif';
	font-weight: 400;
	line-height: 1.6;
}

.App {
  padding-bottom: 80px;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
  background-color: #00e0db;
  margin-bottom: 15px;
}

.header-container img {
  width: 15%;
}

.bold-text {
  font-weight: 600;
}

.center-text {
  font-size: 16px;
  text-align: center;
}

.payment-container {
  width: 500px;
  padding: 10px 15px;
  margin: 0 auto;
  border: 1px solid #eeeeee;
  border-radius: 8px;  
  text-align: center;
}

.thank-you-title {
  font-size: 16px;
  font-weight: 600;
}

.cleaners-title {
  font-size: 14px;
}

.tip-radio {
  opacity: 0;
  position: fixed;
  width: 0;
}

.tip-label {
  display: inline-block;
  background-color: transparent;
  border: 2px solid #eeeeee;
  color: #000000;
  padding: 10px 40px;
  font-size: 16px;
  border-radius: 8px;
  margin-top: 10px;
  transition: all .2s ease-in-out;
}

.tip-label:not(:last-child) {
  margin-right: 8px;
}

.tip-label:hover {
  background-color: #00e0db;
  border: 2px solid #00e0db;
  color: #ffffff;
  cursor: pointer;
}

.tip-label-active {
  background-color: #00e0db;
  border: 2px solid #00e0db;
  color: #ffffff;
}


.tip-submit-btn {
  font-family: 'Poppins', sans-serif;
  background-color: #00e0db;
  border: 1px solid #00e0db;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px 15px;
  width: 100%;
  margin-top: 15px;
  font-size: 19px;
  font-weight: 600;
}

.custom-input-field {
  box-sizing: border-box;
}

.tip-amount-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.gratitude-title {
  margin-top: 5px;
  font-size: 12px;
}


.custom-input-field {
  display: block;
  padding: 10px 15px;
  border: 2px solid #eeeeee;
  border-radius: 8px;
  width: 100%;
}

.tip-submit-btn {
  cursor: pointer;
}

.error-message {
  font-size: 14px;
  color: #b91c1c;
}

.report-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 15px;
}

.report-container {
  width: 50%;
  margin: 0 auto;
}

.tip-request-container {
  border: 1px solid #eeeeee;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.customer-email {
  font-size: 16px;
}

.amount-total, .created-date, .paid-on-date, .employee-names {
  font-size: 14px;
}

.employee-name {
  font-weight: 600;
}

.password-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
}

.password-label {
  font-size: 14px;
}

.password-input {
  padding: 10px 20px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
}

.password-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #00e0db;
  color: #fff;
  border: 1px solid #00e0db;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
}

.password-error-message {
  text-align: center;
}


@media only screen and (max-width: 800px) {
  .header-container img {
    width: 50%;
  }

  .payment-container {
    width: 90%;
  }

  .thank-you-title {
    font-size: 16px;
  }

  .cleaners-title {
    font-size: 14px;
  }

  .tip-label {
    padding: 10px 23px;
  }

  .tip-amount-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .custom-tip-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
  }

  .custom-input-field:focus {
    border: 1px solid #eeeeee;

  }

  .report-container {
    width: 95%;
  }
  
}